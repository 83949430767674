import { Suspense } from "react"
import { useMatch } from "react-router-dom-v5-compat"
import styled from "styled-components"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import InfoIcon from "@material-ui/icons/Error"

import { DunningBanner } from "src/components/AppLayout/Banners/DunningBanner"
import { mainContentPadding } from "src/components/AppLayout/sharedStyles"
import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { breakpoint } from "src/constants/breakpoints"
import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { getImpersonateActive } from "src/data/auth/auth"
import { isMinutMobile } from "src/data/auth/mobileAppAuth"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { PARADISE_PATH } from "src/router/ParadiseRoutes"
import {
  backgroundGrayV2,
  primaryBackground,
  systemEmergencyBackground,
  systemEmergencyForeground,
} from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import { ParadiseSideNav } from "./SideNav/ParadiseSideNav"
import { SideNav } from "./SideNav/SideNav"
import { AppBarMobile } from "./AppBar"

export function LayoutMain({
  children,
  noMaxWidth,
  noPadding,
  ...rest
}: {
  children: React.ReactNode
  noMaxWidth?: boolean
  noPadding?: boolean
}) {
  const smallWidth = useMediaQuery(`(${breakpoint.mediumDown})`)
  const user = useGetUser()
  const impersonate = getImpersonateActive()
  const isInParadise = useMatch(PARADISE_PATH + "/*")

  function DesktopNav() {
    if (isMinutMobile()) return null
    if (smallWidth) return null
    if (isInParadise) return <ParadiseSideNav />
    return <SideNav />
  }

  function MobileNav() {
    if (isMinutMobile()) return null
    if (!smallWidth) return null
    return <AppBarMobile />
  }

  return (
    <MainGrid {...rest}>
      <Header>
        <MobileNav />
      </Header>
      <Left>
        <DesktopNav />
      </Left>
      <Main>
        {!isInParadise && <DunningBanner />}
        <Content $noPadding={noPadding} $noMaxWidth={noMaxWidth}>
          <Suspense fallback={null}>
            <ErrorBoundary internalLink>{children}</ErrorBoundary>
          </Suspense>
        </Content>
      </Main>
      <Right></Right>
      <Footer>
        {impersonate && (
          <ImpersonateBanner>
            <InfoIcon fontSize="large" style={{ marginRight: "1rem" }} />
            <span>Impersonating {user.email}</span>
          </ImpersonateBanner>
        )}
      </Footer>
    </MainGrid>
  )
}

const MainGrid = styled.div`
  display: grid;
  grid-template-areas:
    "topbar topbar topbar"
    "left main right"
    "footer footer footer";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
`

const Header = styled.header`
  grid-area: topbar;
  z-index: 1;
  position: sticky;
  top: 0;
`

const Left = styled.aside`
  grid-area: left;
  background: ${backgroundGrayV2};
`

const Main = styled.main`
  grid-area: main;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
`

const Content = styled.div<{ $noPadding?: boolean; $noMaxWidth?: boolean }>`
  ${({ $noPadding }) => !$noPadding && mainContentPadding};
  ${({ $noMaxWidth }) => !$noMaxWidth && breakpoint.largeDown};

  display: flex;
  flex-direction: column;
  flex: 1;
`
const Right = styled.aside`
  grid-area: right;
  background: cornflowerblue;
`

const Footer = styled.footer`
  grid-area: footer;
  background: ${primaryBackground};
  position: sticky;
  bottom: 0;
  z-index: ${Z_INDEX_ABOVE_MODAL};
`

const ImpersonateBanner = styled.div`
  color: ${systemEmergencyForeground};
  background: ${systemEmergencyBackground}EE;
  padding: ${spacing.M};
`
