import styled from "styled-components"

import { ParadiseProfileButton } from "src/components/AppLayout/ParadiseProfileButton"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { isSalesAdmin, isSuperAdmin } from "src/data/user/logic/accessLogic"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { backgroundColor, colorsV2, palette } from "src/ui/colors"
import CodeIcon from "src/ui/icons/code.svg"
import HomeIcon from "src/ui/icons/home.svg"
import Logo from "src/ui/icons/logo.svg"
import OrganizationIcon from "src/ui/icons/organization.svg"
import SensorIcon from "src/ui/icons/sensor.svg"
import SettingsIcon from "src/ui/icons/sliders.svg"
import TeamsIcon from "src/ui/icons/teams.svg"
import ToolIcon from "src/ui/icons/tool.svg"

import {
  IconBox,
  LogoBox,
  NavFooter,
  NavMain,
  ProfileButtonSpacer,
  SharedStyledNavLink,
  SideNavBox,
} from "./sharedStyles"

export function ParadiseSideNav({
  navLinkClick = () => {},
  ...props
}: {
  navLinkClick?: () => void
}) {
  const { t } = useTranslate()
  const user = useGetUser()

  return (
    <SideNavBox {...props} aria-label="navbar" $background={palette.hejmo}>
      <LogoBox>
        <Logo width={70} fill="#fff" />
      </LogoBox>

      <ProfileButtonSpacer>
        <ParadiseProfileButton />
      </ProfileButtonSpacer>

      <NavMain
        $color={colorsV2.textContrast}
        $hoverBgColor="rgba(255, 255, 255, 0.15)"
        $activeBgColor="rgba(255, 255, 255, 0.15)"
        $activeColor={colorsV2.textContrast}
      >
        {isSuperAdmin(user.roles) ? (
          <>
            <li>
              <StyledNavLink
                to={Routes.ParadiseUsers.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <TeamsIcon />
                </IconBox>
                Users
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink
                to={Routes.ParadiseHomes.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <HomeIcon />
                </IconBox>
                {t(langKeys.home, { count: 2 })}
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink
                to={Routes.ParadiseDevices.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <SensorIcon />
                </IconBox>
                Devices
              </StyledNavLink>
            </li>

            <li>
              <StyledNavLink
                to={Routes.ParadiseOrganizations.location().pathname}
                onClick={navLinkClick}
              >
                <IconBox>
                  <OrganizationIcon />
                </IconBox>
                Organizations
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink
                to={Routes.ParadiseProfiles.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <SettingsIcon color="white" />
                </IconBox>
                Profiles
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink
                to={Routes.ParadiseClients.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <CodeIcon color="white" />
                </IconBox>
                Clients
              </StyledNavLink>
            </li>
            <li>
              <StyledNavLink
                to={Routes.ParadiseFirmwares.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <ToolIcon />
                </IconBox>
                Firmwares
              </StyledNavLink>
            </li>
          </>
        ) : (
          isSalesAdmin(user.roles) && (
            <li>
              <StyledNavLink
                to={Routes.ParadiseUsers.location()}
                onClick={navLinkClick}
              >
                <IconBox>
                  <TeamsIcon />
                </IconBox>
                Users
              </StyledNavLink>
            </li>
          )
        )}
      </NavMain>

      <NavFooter></NavFooter>
    </SideNavBox>
  )
}

const StyledNavLink = styled(SharedStyledNavLink)`
  color: ${backgroundColor};
`
